import { BookingDetails } from '../domain/BookingDetails'
import { BookingStatusType } from '../domain/BookingStatus'

export class BookingDetailsViewModel {

    public showCancelButton: boolean
    public showCancellationStatus = false
    public showBookAgainButton = false
    public showBookingConfirmedStatus = false
    public showBookingRequestedStatus = false
    public showPendingDepositStatus = false
    public showPendingCardDetailsStatus = false
    public showModifyBookingButton = false
    public showGetDirectionsButton = false
    public showViewBookingButton = false
    public showReminderConfirmation = false
    public showPayDepositButton = false
    public showSaveCardDetailsButton = false

    constructor(
        public details: BookingDetails,
        public isShownInWidget: boolean,
        public justConfirmedReminder: boolean
    ) {
        this.showModifyBookingButton = !isShownInWidget && this.details.modifyBookingPath !== null
        this.showCancelButton = !isShownInWidget &&
            (details.statusType === BookingStatusType.Booked ||
                details.statusType === BookingStatusType.Requested)
        this.showCancellationStatus = details.statusType === BookingStatusType.Cancelled
        this.showBookAgainButton = isShownInWidget &&
            (details.statusType === BookingStatusType.Booked ||
                details.statusType === BookingStatusType.Requested)
        this.showBookingConfirmedStatus = details.statusType === BookingStatusType.Booked &&
            !justConfirmedReminder
        this.showBookingRequestedStatus = details.statusType === BookingStatusType.Requested &&
            !justConfirmedReminder
        this.showPendingDepositStatus = details.statusType === BookingStatusType.PendingPayment &&
            details.depositCharge !== null
        this.showPendingCardDetailsStatus = details.statusType === BookingStatusType.PendingPayment &&
            details.cancellationCharge !== null
        this.showGetDirectionsButton = details.statusType === BookingStatusType.Booked ||
            details.statusType === BookingStatusType.Requested
        this.showViewBookingButton = isShownInWidget
        this.showPayDepositButton = details.statusType === BookingStatusType.PendingPayment &&
            details.depositCharge !== null
        this.showSaveCardDetailsButton = details.statusType === BookingStatusType.PendingPayment &&
            details.cancellationCharge !== null
        this.showReminderConfirmation = justConfirmedReminder
    }
}
