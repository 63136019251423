import { Answer } from './Answer'
import { BookingSlot } from './BookingSlot'

export class BookingRequest {

    constructor(
        public bookingSlot: BookingSlot,
        public firstName: string,
        public lastName: string | null,
        public emailAddress: string,
        public phoneNumber: string | null,
        public partySize: number,
        public notes: string | null,
        public agreedToMarketing: boolean,
        public agreedToTerms: boolean,
        public paymentMethodId: string | null,
        public reasonId: string | null,
        public eventId: string | null,
        public answers: Answer[],
        public depositPaymentMethodId: string | null,
        public requiresWheelchairAccess: boolean
    ) { }
}
