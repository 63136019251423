import { Availability } from '../domain/Availability'
import { BookingDetails, BookingType } from '../domain/BookingDetails'
import { BookingRequestFactory } from '../domain/BookingRequestFactory'
import { BookingService } from '../services/booking.service'
import { Component, Input, OnDestroy, OnInit, numberAttribute } from '@angular/core'
import { Context } from '../domain/Context'
import { ContextService } from '../services/ContextService'
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    ValidatorFn,
    Validators,
} from '@angular/forms'
import { FormStep } from '../booking-form/booking-form.component'
import { HttpErrorResponse } from '@angular/common/http'
import { NGBDateTimeAdapter } from '../utilities/NgbDateTimeAdapter'
import { NgbDateNativeAdapter, NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap'
import { PatternValidators } from '../utilities/pattern-validators'
import { Question } from '../domain/Question'
import { RxwebValidators } from '@rxweb/reactive-form-validators'
import { Subject, finalize, takeUntil } from 'rxjs'
import { Venue } from '../domain/Venue'
import { environment } from '../../environments/environment'

@Component({
    selector: 'app-booking-modal',
    templateUrl: './booking-modal.component.html',
})
export class BookingModalComponent implements OnInit, OnDestroy {

    @Input() organisationId!: string
    @Input() businessId!: string
    @Input() venueId!: string
    @Input({ transform: numberAttribute }) partySize?: number
    @Input({ transform: (value: string) => new Date(value) }) date?: Date
    @Input({ transform: (value: string) => new Date(value) }) idealTime?: Date
    form!: FormGroup
    step!: FormStep
    venue?: Venue
    confirmationBookingDetails?: BookingDetails
    formStep = FormStep
    isLoading = false
    requestBookingError?: string
    marketingUrl = environment.marketingUrl
    private ngbDateAdapter = new NgbDateNativeAdapter()
    private ngbTimeAdapter = new NGBDateTimeAdapter()
    private onDestroy$ = new Subject<void>()

    constructor(
        private bookingService: BookingService,
        private contextService: ContextService,
        private fb: FormBuilder
    ) {
        this.form = this.makeForm()
    }

    ngOnInit() {
        this.setBookingContextIfProvided()
        this.bindAvailabilityToVenue()
        this.step = FormStep.BookingSlot
    }

    private setBookingContextIfProvided() {
        const bookingSlotForm = this.form.get(FormStep[FormStep.BookingSlot]) as FormGroup
        if (this.partySize) {
            bookingSlotForm
                .get('partySize')!
                .setValue(this.partySize)
        }
        if (this.date) {
            bookingSlotForm
                .get('date')!
                .setValue(this.ngbDateAdapter.fromModel(this.date))
        }
        if (this.idealTime) {
            bookingSlotForm
                .get('idealTime')!
                .setValue(this.idealTime)
        }
    }

    ngOnDestroy() {
        this.onDestroy$.next()
        this.onDestroy$.complete()
    }

    bookingDetailsSelected(_: FormGroup, availability: Availability) {
        this.setWheelchairAccessRequiredValidator(availability)
        this.setPaymentDetailsRequired(availability)
        this.setDepositRequired(availability)
        this.setPhoneNumberRequired(availability.venue)
        this.setReasonRequired(availability.venue)
        this.setSelectedReasonQuestions(availability.venue)
        this.step = FormStep.BookingDetails
    }

    clearRequestBookingError() {
        this.requestBookingError = undefined
    }

    goBackFromBookingDetails() {
        this.step = FormStep.BookingSlot
    }

    goNextFromBookingDetails() {
        const paymentDetailsRequiredControl = this.form
            .get(FormStep[FormStep.BookingDetails])
            ?.get('paymentDetailsRequired') as FormControl
        const depositRequiredControl = this.form
            .get(FormStep[FormStep.BookingDetails])
            ?.get('depositRequired') as FormControl
        if (paymentDetailsRequiredControl.value) {
            this.step = FormStep.PaymentDetails
        } else if (depositRequiredControl.value) {
            this.step = FormStep.Deposit
        }
    }

    goBackFromPaymentDetails() {
        this.requestBookingError = undefined
        this.step = FormStep.BookingDetails
    }

    goBackFromDeposit() {
        this.requestBookingError = undefined
        this.step = FormStep.BookingDetails
    }

    bookSelected() {
        const factory = new BookingRequestFactory(this.form)
        const request = factory.make()
        const bookingSlotForm = this.form.get(FormStep[FormStep.BookingSlot]) as FormGroup
        const areaId = bookingSlotForm.get('selectedAreaId')?.value
        this.requestBookingError = undefined
        this.isLoading = true
        return this.bookingService.requestBooking(
            new Context(this.organisationId, this.businessId, this.venueId),
            areaId,
            request
        )
            .pipe(
                takeUntil(this.onDestroy$),
                finalize(() => this.isLoading = false)
            )
            .subscribe({
                next: bookingDetails => {
                    this.confirmationBookingDetails = bookingDetails
                    this.step = FormStep.Confirmation
                },
                error: error => {
                    this.requestBookingError = this.mapErrorResponseToError(error)
                },
            })
    }

    bookAgainSelected() {
        this.confirmationBookingDetails = undefined
        this.form = this.makeForm()
        this.step = FormStep.BookingSlot
    }

    dismiss() {
        window.parent.postMessage(
            {
                type: 'close-booking-modal',
                payload: {
                },
            },
            '*'
        )
    }

    private makeForm() {
        return this.fb.group({
            'BookingSlot': this.fb.group({
                availability: [null, Validators.required],
                step: [null, Validators.required],
                partySize: [null, Validators.required],
                idealTime: [null, Validators.required],
                date: [null, Validators.required],
                requiresWheelchairAccess: [null],
                selectedAreaId: [null, Validators.required],
                areaSelectionIsPossible: [true, Validators.required],
                bookingType: [null, Validators.required],
                selectedEventId: [null],
                selectedReasonId: [null],
                time: [null, Validators.required],
                endTime: [null, Validators.required],
            }),
            'BookingDetails': this.fb.group({
                paymentDetailsRequired: [false, Validators.required],
                depositRequired: [false, Validators.required],
                firstName: [null, Validators.required],
                lastName: [null, Validators.nullValidator],
                emailAddress: [null, [Validators.required, RxwebValidators.email()]],
                phoneNumber: [null],
                notes: [null],
                questions: new FormArray([]),
                agreedToMarketing: [false, Validators.required],
                agreedToTerms: [false, Validators.requiredTrue],
            }),
            'PaymentDetails': this.fb.group({
                paymentMethodId: [null, null],
            }),
            'Deposit': this.fb.group({
                paymentMethodId: [null, null],
            }),
        })
    }

    private setWheelchairAccessRequiredValidator(availability: Availability) {
        const wheelchairAccessControl = this.form
            .get(FormStep[FormStep.BookingSlot])
            ?.get('requiresWheelchairAccess') as FormControl
        const needsToBeSet = availability.hasAWheelchairRestrictedTable()
        if (needsToBeSet) {
            wheelchairAccessControl.setValidators([Validators.required])
        } else {
            wheelchairAccessControl.clearValidators()
            wheelchairAccessControl.setValue(null)
        }
    }

    private setPaymentDetailsRequired(availability: Availability) {
        const areaId = this.form
            .get(FormStep[FormStep.BookingSlot])
            ?.get('selectedAreaId')?.value as string
        const partySize = this.form
            .get(FormStep[FormStep.BookingSlot])
            ?.get('partySize')?.value as number
        const reasonId = this.form
            .get(FormStep[FormStep.BookingSlot])
            ?.get('selectedReasonId')?.value as string | null
        const eventId = this.form
            .get(FormStep[FormStep.BookingSlot])
            ?.get('selectedEventId')?.value as string | null
        const ngbTime = this.form
            .get(FormStep[FormStep.BookingSlot])
            ?.get('time')?.value as NgbTimeStruct
        const ngbDate = this.form
            .get(FormStep[FormStep.BookingSlot])
            ?.get('date')?.value as NgbDateStruct
        const date = this.ngbDateAdapter.toModel(ngbDate)!
        const time = this.ngbTimeAdapter.toModel(ngbTime)!
        const dateTime = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            time.getHours(),
            time.getMinutes()
        )
        const required = availability.reservableOptionWithConfiguration(
            areaId,
            partySize,
            reasonId,
            eventId,
            dateTime
        )?.cancellationChargeAmount !== null
        const paymentDetailsRequiredControl = this.form
            .get(FormStep[FormStep.BookingDetails])
            ?.get('paymentDetailsRequired') as FormControl
        paymentDetailsRequiredControl.setValue(required)
    }

    private setDepositRequired(availability: Availability) {
        const areaId = this.form
            .get(FormStep[FormStep.BookingSlot])
            ?.get('selectedAreaId')?.value as string
        const partySize = this.form
            .get(FormStep[FormStep.BookingSlot])
            ?.get('partySize')?.value as number
        const reasonId = this.form
            .get(FormStep[FormStep.BookingSlot])
            ?.get('selectedReasonId')?.value as string | null
        const eventId = this.form
            .get(FormStep[FormStep.BookingSlot])
            ?.get('selectedEventId')?.value as string | null
        const ngbTime = this.form
            .get(FormStep[FormStep.BookingSlot])
            ?.get('time')?.value as NgbTimeStruct
        const ngbDate = this.form
            .get(FormStep[FormStep.BookingSlot])
            ?.get('date')?.value as NgbDateStruct
        const date = this.ngbDateAdapter.toModel(ngbDate)!
        const time = this.ngbTimeAdapter.toModel(ngbTime)!
        const dateTime = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            time.getHours(),
            time.getMinutes()
        )
        const required = availability.reservableOptionWithConfiguration(
            areaId,
            partySize,
            reasonId,
            eventId,
            dateTime
        )?.depositAmount !== null
        const depositRequiredControl = this.form
            .get(FormStep[FormStep.BookingDetails])
            ?.get('depositRequired') as FormControl
        depositRequiredControl.setValue(required)
    }

    private setPhoneNumberRequired(venue: Venue) {
        const validators = [
            PatternValidators.nonAlphabetical,
        ]
        const phoneNumberControl = this.form
            .get(FormStep[FormStep.BookingDetails])
            ?.get('phoneNumber') as FormControl
        if (venue.phoneNumberRequired) {
            phoneNumberControl.setValidators([...validators, Validators.required])
        } else {
            phoneNumberControl.setValidators(validators)
        }
    }

    private setReasonRequired(venue: Venue) {
        const reasonControl = this.form
            .get(FormStep[FormStep.BookingSlot])
            ?.get('selectedReasonId') as FormControl
        if (this.isReasonRequired(venue)) {
            reasonControl.setValidators([Validators.required])
        } else {
            reasonControl.clearValidators()
        }
    }

    private isReasonRequired(venue: Venue) {
        const form = this.form.get(FormStep[FormStep.BookingSlot]) as FormGroup
        const venueHasReasons = venue.reasons.length > 0
        const bookingType = form.get('bookingType')?.value
        if (bookingType === BookingType.Event) {
            return false
        }
        return venueHasReasons
    }

    private setSelectedReasonQuestions(venue: Venue) {
        const questionControls = this.form
            .get(FormStep[FormStep.BookingDetails])
            ?.get('questions') as FormArray
        const reasonControl = this.form
            .get(FormStep[FormStep.BookingSlot])
            ?.get('selectedReasonId') as FormControl
        const eventControl = this.form
            .get(FormStep[FormStep.BookingSlot])
            ?.get('selectedEventId') as FormControl
        const questions = venue.questionsToAskWhenBookingForConfiguration(
            reasonControl.value,
            eventControl.value
        )
        questionControls.clear()
        questions.forEach(question => {
            let validators = this.validatorsForQuestion(question)
            questionControls.push(this.fb.group({
                question: new FormControl(question, Validators.required),
                answer: new FormControl(null, validators),
            }))
        })
    }

    private validatorsForQuestion(question: Question) {
        const isRequired = question.required && !question.isTickable
        const isRequiredTrue = question.required && question.isTickable
        let validators: ValidatorFn[] = []
        if (isRequired) {
            validators.push(Validators.required)
        }
        if (isRequiredTrue) {
            validators.push(Validators.requiredTrue)
        }
        return validators
    }

    private mapErrorResponseToError(error: HttpErrorResponse) {
        if (error.status === 0) {
            return 'There was a connection problem. Please try again.'
        }
        return 'There was a problem requesting your booking. Please try again.'
    }

    private bindAvailabilityToVenue() {
        this.form
            .get(FormStep[FormStep.BookingSlot])
            ?.get('availability')
            ?.valueChanges
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(availability => {
                this.venue = availability.venue
            })
    }
}
