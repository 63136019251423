<div
    *ngIf="isLoadingAvailability"
    class="hstack justify-content-center"
    style="min-height: 180px;"
>
    <div class="spinner-border" role="status"></div>
</div>
<form
    *ngIf="availability$ | async as availability"
    class="form-group vstack gap-3"
    appVenueColour
    [primaryColour]="availability.venue.primaryColour"
>
    <ng-container
        *ngIf="form.get('step')?.value !== ModifyBookingStep.SelectTableAndDate"
    >
        <ng-container
            *ngTemplateOutlet="summary; context: { availability: availability }"
        >
        </ng-container>
    </ng-container>
    <ng-container
        [ngSwitch]="form.get('step')?.value"
    >
        <ng-container
            *ngSwitchCase="ModifyBookingStep.SelectTableAndDate"
        >
            <ng-container
                *ngTemplateOutlet="selectPartySizeAndDate; context:  {
                    availability: availability,
                }"
            >
            </ng-container>
        </ng-container>
        <ng-container
            *ngSwitchCase="ModifyBookingStep.SelectRestrictions"
        >
            <ng-container
                *ngTemplateOutlet="selectRestrictions"
            >
            </ng-container>
        </ng-container>
        <ng-container
            *ngSwitchCase="ModifyBookingStep.SelectBookingType"
        >
            <ng-container
                *ngTemplateOutlet="selectBookingType; context: {
                    availability: availability,
                }"
            >
            </ng-container>
        </ng-container>
        <ng-container
            *ngSwitchCase="ModifyBookingStep.SelectReason"
        >
            <ng-container
                *ngTemplateOutlet="selectReason; context: {
                    availability: availability,
                }"
            >
            </ng-container>
        </ng-container>
        <ng-container
            *ngSwitchCase="ModifyBookingStep.SelectArea"
        >
            <ng-container
                *ngTemplateOutlet="selectArea; context: {
                    availability: availability,
                }"
            >
            </ng-container>
        </ng-container>
        <ng-container
            *ngSwitchCase="ModifyBookingStep.SelectTime"
        >
            <ng-container
                *ngIf="!availability.isConfigurationReservable(
                    form.get('partySize')?.value,
                    form.get('selectedReasonId')?.value,
                    form.get('selectedEventId')?.value
                )"
            >
                <app-no-availability
                    [availability]="availability"
                    [areaId]="form.get('selectedAreaId')?.value"
                >
                </app-no-availability>
            </ng-container>
            <ng-container
                *ngIf="availability.isConfigurationReservable(
                    form.get('partySize')?.value,
                    form.get('selectedReasonId')?.value,
                    form.get('selectedEventId')?.value
                )"
            >
                <ng-container
                    *ngTemplateOutlet="selectTime; context: {
                        areaId: form.get('selectedAreaId')!.value,
                        reasonId: form.get('selectedReasonId')!.value,
                        eventId: form.get('selectedEventId')!.value,
                        partySize: form.get('partySize')!.value,
                        idealTime: form.get('idealTime')!.value,
                        availability: availability,
                    }"
                >
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
    @if (form.valid) {
        <button
            *ngIf="!bookingDetails"
            class="btn btn-primary"
            type="button"
            data-test="confirm-details"
            (click)="save(availability)"
        >
            Confirm & Continue
        </button>
        <button
            *ngIf="bookingDetails"
            class="btn btn-primary"
            type="button"
            [disabled]="!haveBookingDetailsChanged"
            (click)="save(availability)"
        >
            Confirm Changes
        </button>
    }
</form>

<ng-template
    #summary
    let-availability="availability"
>
    <div class="vstack gap-1 flex-grow-0">
        <div
            *ngIf="form.get('step')?.value !== ModifyBookingStep.SelectTableAndDate"
            class="btn btn-light px-3 py-2 rounded hstack gap-2 align-items-baseline"
            (click)="selectTableAndDateStep()"
        >
            <label class="flex-shrink-0">
                Table for
            </label>
            <div
                class="hstack gap-1 justify-content-end flex-grow-1 flex-shrink-1 fw-semibold text-primary text-nowrap overflow-auto"
            >
                <span class="text-truncate">
                    {{ form.get('partySize')?.value | i18nPlural: partySizePluralMapping }}
                    ·
                    {{ form.get('date')?.value | ngbDate | date: 'mediumDate' }}
                </span>
                <img ngSrc="assets/pencil/normal.svg" width="24" height="24" />
            </div>
        </div>
        <div
            *ngIf="availability.hasAWheelchairRestrictedTable() && form.get('requiresWheelchairAccess')?.value !== null"
            class="btn btn-light px-3 py-2 rounded hstack gap-2 align-items-baseline"
            (click)="selectRestrictionsStep()"
        >
            <label class="flex-shrink-0">
                Accessibility
            </label>
            <div
                class="hstack gap-1 justify-content-end flex-grow-1 flex-shrink-1 fw-semibold text-primary overflow-auto"
            >
                <span class="text-truncate">
                    {{ form.get('requiresWheelchairAccess')?.value ? 'Wheelchair Access' : 'No Requirements' }}
                </span>
                <img ngSrc="assets/pencil/normal.svg" width="24" height="24" />
            </div>
        </div>
        <div
            *ngIf="form.get('bookingType')?.value !== null && availability.reservableEvents().length > 0"
            class="btn btn-light px-3 py-2 rounded hstack gap-1 align-items-baseline"
            [ngClass]="{ disabled: form.get('selectedEventId')?.disabled }"
            (click)="selectBookingTypeStep()"
        >
            <label>
                Booking Type
            </label>
            <div
                class="hstack gap-2 justify-content-end flex-grow-1 fw-semibold text-primary"
            >
                <ng-container
                    *ngIf="form.get('bookingType')?.value === BookingType.Standard"
                >
                    Standard Booking
                </ng-container>
                <ng-container
                    *ngIf="form.get('bookingType')?.value === BookingType.Event"
                >
                    {{ form.get('selectedEventId')?.value | eventName : availability.venue }}
                </ng-container>
                <img ngSrc="assets/pencil/normal.svg" width="24" height="24" />
            </div>
        </div>
        <div
            *ngIf="form.get('selectedReasonId')?.value !== null"
            class="btn btn-light px-3 py-2 rounded hstack gap-2 align-items-baseline"
            (click)="selectReasonStep()"
        >
            <span class="flex-shrink-0">
                Reason for your visit
            </span>
            <div
                class="hstack gap-2 justify-content-end flex-grow-1 flex-shrink-1 fw-semibold text-primary overflow-auto"
            >
                <span class="text-truncate">
                    {{ form.get('selectedReasonId')?.value | reasonName : availability.venue }}
                </span>
                <img ngSrc="assets/pencil/normal.svg" width="24" height="24" />
            </div>
        </div>
        <div
            *ngIf="availability.areas.length > 1 && form.get('selectedAreaId')?.value !== null && this.form.get('areaSelectionIsPossible')?.value"
            class="btn btn-light px-3 py-2 rounded hstack gap-2 align-items-baseline"
            (click)="selectAreaStep()"
        >
            <label class="flex-shrink-0">
                Seating Area
            </label>
            <div
                class="hstack gap-2 justify-content-end flex-grow-1 flex-shrink-1 fw-semibold text-primary overflow-auto"
            >
                <span class="text-truncate">
                    {{ form.get('selectedAreaId')?.value | areaName : availability }}
                </span>
                <img ngSrc="assets/pencil/normal.svg" width="24" height="24" />
            </div>
        </div>
        <div
            *ngIf="form.get('time')?.value !== null"
            class="btn btn-light px-3 py-2 rounded hstack gap-2 align-items-baseline"
            (click)="selectTimeStep()"
        >
            <label class="flex-shrink-0">
                Time
            </label>
            <div
                class="hstack gap-2 justify-content-end flex-grow-1 flex-shrink-1 fw-semibold text-primary overflow-auto"
            >
                <span class="text-truncate">
                    {{ form.get('time')?.value | ngbTime | date: 'shortTime' }}
                    -
                    {{ form.get('endTime')?.value | ngbTime | date: 'shortTime' }}
                </span>
                <img ngSrc="assets/pencil/normal.svg" width="24" height="24" />
            </div>
        </div>
    </div>
</ng-template>

<ng-template
    #selectPartySizeAndDate
    [formGroup]="form"
    let-availability="availability"
>
    <ng-container>
        <div class="vstack gap-2">
            @if (bookingDetails?.isDateRestricted === true) {
                <div
                    class="alert alert-info"
                    role="alert"
                >
                    <ng-container
                        *ngTemplateOutlet="dateRestrictedMessage"
                    >
                    </ng-container>
                </div>
            }
            <div class="position-relative">
                <div class="btn-group dropdown w-100">
                    <button
                        class="btn btn-outline-secondary dropdown-toggle hstack gap-2"
                        [disabled]="bookingDetails?.isDateRestricted ?? false"
                        (click)="partySizeSelect.click()"
                    >
                        <img
                            ngSrc="assets/people/normal.svg"
                            width="20"
                            height="20"
                            alt="party size"
                        >
                        <span
                            *ngIf="form.get('partySize')?.value === availability.venue.minLargePartySize"
                        >
                            {{ availability.venue.minLargePartySize | i18nPlural: partySizePluralMapping }} +
                        </span>
                        <span
                            *ngIf="form.get('partySize')?.value !== availability.venue.minLargePartySize"
                        >
                            {{ form.get('partySize')?.value | i18nPlural: partySizePluralMapping }}
                        </span>
                    </button>
                </div>
                <select
                    #partySizeSelect
                    class="form-select btn opacity-0 position-absolute top-0 start-0 w-100 h-100"
                    formControlName="partySize"
                >
                    <option
                        *ngFor="let size of availability.possiblePartySizes"
                        [ngValue]="size"
                    >
                        {{ size | i18nPlural: partySizePluralMapping }}
                    </option>
                    <option
                        *ngIf="availability.venue.minLargePartySize !== null"
                        class="dropdown-item"
                        [ngValue]="availability.venue.minLargePartySize"
                    >
                        {{ availability.venue.minLargePartySize }}+
                    </option>
                </select>
            </div>
            <ng-container
                *ngIf="availability.venue.minLargePartySize !== null && form.get('partySize')?.value >= availability.venue.minLargePartySize"
            >
                <ng-container
                    *ngTemplateOutlet="largePartySizeMessage; context: { availability: availability }"
                >
                </ng-container>
            </ng-container>
            <ng-container
                *ngIf="availability.venue.minLargePartySize === null || form.get('partySize')?.value < availability.venue.minLargePartySize"
            >
                <div class="hstack gap-2">
                    <div
                        class="d-flex flex-grow-1"
                    >
                        <input
                            #datePicker="ngbDatepicker"
                            class="form-control p-0 m-0 border-0"
                            formControlName="date"
                            ngbDatepicker
                            style="width: 0; height: 0"
                            navigation="arrows"
                            placement="bottom-left"
                            [minDate]="earliestDate"
                            [disabled]="bookingDetails?.isDateRestricted ?? false"
                        />
                        <button
                            class="btn btn-outline-secondary dropdown-toggle flex-grow-1"
                            [disabled]="bookingDetails?.isDateRestricted ?? false"
                            (click)="datePicker.toggle()"
                        >
                            <div class="calendar"></div>
                            {{ form.get('date')?.value | ngbDate | relativeDate: 'mediumDate' }}
                        </button>
                    </div>
                    <div class="position-relative btn-group dropdown flex-grow-1">
                        <button
                            class="btn btn-outline-secondary dropdown-toggle flex-grow-1"
                            (click)="idealTimeSelect.click()"
                        >
                            <div class="clock"></div>
                            {{ form.get('idealTime')?.value | date: 'shortTime' }}
                        </button>
                        <select
                            #idealTimeSelect
                            class="form-select btn opacity-0 position-absolute top-0 start-0 w-100 h-100"
                            formControlName="idealTime"
                            [compareWith]="Helper.areDatesEqual"
                        >
                            <option
                                *ngFor="let time of availability.intervalIdealTimes"
                                [ngValue]="time"
                            >
                                {{ time | date: 'shortTime' }}
                            </option>
                        </select>
                    </div>
                </div>
            </ng-container>
        </div>
        <div
            *ngIf="availability.venue.minLargePartySize === null || form.get('partySize')?.value < availability.venue.minLargePartySize"
            class="vstack justify-content-end"
        >
            <button
                class="btn btn-primary"
                type="button"
                data-test="check-availability"
                (click)="checkAvailabilitySelected()"
            >
                Check Availability
            </button>
        </div>
    </ng-container>
</ng-template>

<ng-template
    #dateRestrictedMessage
>
    <div>
        Some details of this booking cannot be changed due to card details being required.
    </div>
</ng-template>

<ng-template
    #selectRestrictions
    [formGroup]="form"
>
    <div class="hstack justify-content-between">
        <h5 class="fw-semibold m-0 px-3">
            Accessibility
        </h5>
        <button
            *ngIf="bookingDetails"
            class="btn btn-close btn-block"
            type="button"
            (click)="reset()"
        >
        </button>
    </div>
    <app-accessibility-form
        [isLoading]="isLoadingAvailability"
        (restrictionsSelected)="restrictionsSelected()"
    >
    </app-accessibility-form>
</ng-template>

<ng-template
    #selectBookingType
    [formGroup]="form"
    let-availability="availability"
>
    <div class="vstack flex-grow-0">
        <div class="hstack justify-content-between">
            <h5 class="fw-semibold m-0 px-3">
                Booking Type
            </h5>
        </div>
    </div>
    <div class="vstack gap-2">
        <div class="vstack gap-2 px-2">
            <ng-container
                *ngIf="availability.hasReservableStandardBookingOptions()"
            >
                <hr class="m-0 border-secondary-subtle">
                <div class="d-flex flex-column flex-sm-row gap-2 align-items-baseline align-content-sm-stretch">
                    <div class="flex-grow-1">
                        <span class="fw-semibold">
                            Standard Booking
                        </span>
                    </div>
                    <button
                        class="btn btn-outline-secondary"
                        (click)="normalBookingTypeSelected(availability)"
                    >
                        Select
                    </button>
                </div>
            </ng-container>
            <ng-container
                *ngFor="let event of availability.reservableEvents()"
            >
                <hr class="m-0 border-secondary-subtle">
                <div class="vstack gap-3">
                    <span class="fw-semibold mb-1">
                        {{ event.displayName }}
                    </span>
                    <div class="d-flex flex-column flex-sm-row gap-3">
                        <div class="hstack gap-3 align-items-start">
                            <img
                                *ngIf="event.imageId !== null"
                                [ngSrc]="environment.cdnUrl | thumbor : 'event-images/' + event.imageId : { width: 82, height: 82, aspectFit: false }"
                                class="rounded object-fit-cover"
                                width="82"
                                height="82"
                            />
                            <div class="vstack gap-1">
                                <div class="hstack gap-2">
                                    <div class="vstack gap-2 flex-grow-1">
                                    <span
                                        *ngIf="event.description"
                                        class="small lh-sm"
                                    >
                                        {{ event.description }}
                                    </span>
                                        <span
                                            *ngIf="event.cancellationChargeAmount"
                                            class="small lh-sm text-secondary"
                                        >
                                        Cancellation charges apply
                                    </span>
                                        <span
                                            *ngIf="event.depositAmount"
                                            class="hstack gap-1 small lh-sm text-secondary"
                                        >
                                        <img
                                            ngSrc="assets/tag/normal_small.svg"
                                            width="16"
                                            height="16"
                                        />
                                            {{ event.depositAmount / 100 | currency: availability.venue.currencyCode }}
                                            per person
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="hstack gap-3 align-items-start">
                            <button
                                class="btn btn-outline-secondary"
                                (click)="eventSelected(event, availability)"
                            >
                                Select
                            </button>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</ng-template>

<ng-template
    #selectReason
    [formGroup]="form"
    let-availability="availability"
>
    <app-reason-selection
        [availability]="availability"
        [form]="form"
        (reasonSelected)="reasonSelected($event, availability)"
    >
    </app-reason-selection>
</ng-template>

<ng-template
    #selectArea
    [formGroup]="form"
    let-availability="availability"
>
    <div class="hstack justify-content-between">
        <h5 class="fw-semibold m-0 px-3">
            Seating Area
        </h5>
    </div>
    <div class="vstack gap-2">
        <ng-container
            *ngIf="!availability.isConfigurationReservable(
                form.get('partySize')?.value,
                form.get('selectedReasonId')?.value,
                form.get('selectedEventId')?.value
            )"
        >
            <app-no-availability
                [availability]="availability"
                [areaId]="form.get('selectedAreaId')?.value"
            >
            </app-no-availability>
        </ng-container>
        <ng-container
            *ngIf="availability.isConfigurationReservable(
                form.get('partySize')?.value,
                form.get('selectedReasonId')?.value,
                form.get('selectedEventId')?.value
            )"
        >
            <ng-container
                *ngFor="let area of availability.areasSortedByDisplayOrder"
            >
                <label
                    *ngIf="availability.areaIsConfiguredForChoices(
                        area.area.id,
                        form.get('selectedReasonId')?.value,
                        form.get('selectedEventId')?.value
                    )"
                    class="btn btn-outline-secondary p-2 d-flex flex-column justify-content-center align-items-center"
                    [class.disabled]="
                        !area.areaCanBeBooked ||
                        !availability.areaIsReservableWithConfiguration(
                            area.area.id,
                            form.get('partySize')?.value,
                            form.get('selectedReasonId')?.value,
                            form.get('selectedEventId')?.value
                        )"
                    data-test="area-select"
                >
                    <div class="m-1 hstack gap-2">
                        <input
                            type="radio"
                            [value]="area.area.id"
                            [checked]="area.area.id === form.get('selectedAreaId')?.value"
                            (change)="areaSelected(area.area)"
                            class="form-check-input m-0"
                            role="button"
                        >
                        <div class="vstack align-items-start">
                            <span class="fw-semibold">
                                {{ area.area.displayName }}
                            </span>
                            <span
                                *ngIf="area.area.description"
                                    class="small"
                            >
                                {{ area.area.description }}
                            </span>
                            <span
                                *ngIf="!area.areaCanBeBooked ||
                                !availability.areaIsReservableWithConfiguration(
                                    area.area.id,
                                    form.get('partySize')?.value,
                                    form.get('selectedReasonId')?.value,
                                    form.get('selectedEventId')?.value
                                )"
                                class="small"
                            >
                                Unavailable
                            </span>
                        </div>
                    </div>
                </label>
            </ng-container>
        </ng-container>
    </div>
</ng-template>

<ng-template
    #selectTime
    [formGroup]="form"
    let-areaId="areaId"
    let-reasonId="reasonId"
    let-eventId="eventId"
    let-partySize="partySize"
    let-idealTime="idealTime"
    let-availability="availability"
>
    <div class="hstack justify-content-between">
        <div class="px-3 vstack gap-2">
            <h5 class="fw-semibold m-0">
                Time
            </h5>
            <ng-container
                *ngIf="availability.venue.shortPreBookingWindowMessage && availability.hasBookingSlotsInsideCutOffTimeForAreaId(areaId)"
            >
                <span [innerHTML]="availability.venue.shortPreBookingWindowMessage"></span>
            </ng-container>
            <ng-container
                *ngIf="!availability.venue.shortPreBookingWindowMessage && availability.smallestActivePreBookingWindow(areaId); let preBookingWindow"
            >
                <label
                    *ngIf="availability.hasBookingSlotsInsideCutOffTimeForAreaId(areaId)"
                    class="small text-secondary fw-semibold">
                    To book less than {{ preBookingWindow | duration : DurationUnit.Minute }} in advance,
                    please get in touch at
                    <a [href]="'tel:' + availability.venue.phoneNumber">
                        {{ availability.venue.phoneNumber }}
                    </a>
                    <ng-container *ngIf="availability.venue.emailAddress; let emailAddress">
                        or <a [href]="'mailto:' + emailAddress">{{ emailAddress }}</a>
                    </ng-container>.
                </label>
            </ng-container>
        </div>
        <button
            *ngIf="bookingDetails"
            class="btn btn-close btn-block"
            type="button"
            (click)="reset()"
        >
        </button>
    </div>
    <div>
        <app-booking-slot-selection
            [areaId]="areaId"
            [reasonId]="reasonId"
            [eventId]="eventId"
            [partySize]="partySize"
            [idealTime]="idealTime"
            [availability]="availability"
        >
        </app-booking-slot-selection>
    </div>
</ng-template>

<ng-template
    #largePartySizeMessage
    let-availability="availability"
>
    <div class="text-center fw-semibold p-3">
        <ng-container
            *ngIf="availability.venue.largePartyMessage"
        >
            <span [innerHTML]="availability.venue.largePartyMessage"></span>
        </ng-container>
        <ng-container
            *ngIf="!availability.venue.largePartyMessage"
        >
            To ensure the best possible experience, we ask that you get in touch at
            <a [href]="availability.venue.phoneNumber"> {{ availability.venue.phoneNumber }}</a>
            <ng-container
                *ngIf="availability.venue.emailAddress"
            >
                or
                <a [href]="'mailto:' + availability.venue.emailAddress"> {{ availability.venue.emailAddress }}</a>
            </ng-container>
            to make your booking.
            <br/>
            We look forward to seeing you soon.
        </ng-container>
    </div>
</ng-template>
