<div
    class="d-block modal fade show"
    appVenueColour
>
    <div
        *ngIf="venue"
        class="text-white small position-absolute bottom-0 d-none d-sm-flex gap-2 justify-content-center align-items-center w-100 p-3"
    >
        {{ venue.displayName }} collaborates with TableSense to simplify operations and create lasting guest experiences.
        <a
            [href]="marketingUrl"
            target="_blank"
        >
            Learn more
        </a>
    </div>
    <div class="modal-fullscreen-sm-down modal-dialog d-flex flex-column mt-0 mb-0 align-items-center justify-content-center min-vh-100">
        <div class="modal-content m-0 mb-sm-3">
            <app-spinner-overlay
                [showSpinner]="isLoading"
            >
            </app-spinner-overlay>
            <ng-container
                [ngSwitch]="step"
            >
                <ng-container
                    *ngSwitchCase="formStep.BookingSlot"
                >
                    <ng-container
                        *ngTemplateOutlet="bookingSlot"
                    >
                    </ng-container>
                </ng-container>
                <ng-container
                    *ngSwitchCase="formStep.BookingDetails"
                >
                    <ng-container
                        *ngTemplateOutlet="bookingDetails"
                    >
                    </ng-container>
                </ng-container>
                <ng-container
                    *ngSwitchCase="formStep.PaymentDetails"
                >
                    <ng-container
                        *ngTemplateOutlet="paymentDetails"
                    >
                    </ng-container>
                </ng-container>
                <ng-container
                    *ngSwitchCase="formStep.Deposit"
                >
                    <ng-container
                        *ngTemplateOutlet="deposit"
                    >
                    </ng-container>
                </ng-container>
                <ng-container
                    *ngSwitchCase="formStep.Confirmation"
                >
                    <ng-container
                        *ngTemplateOutlet="confirmation"
                    >
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #bookingSlot>
    <div class="modal-header d-flex justify-content-between">
        <h5 class="modal-title fw-semibold">New Booking</h5>
        <button
            type="button"
            class="btn-close"
            (click)="dismiss()"
        >
        </button>
    </div>
    <div
        class="modal-body p-3 pb-4 flex-fill"
        [formGroup]="form"
    >
        <app-modal-booking-form
            class="h-100 vstack"
            [organisationId]="organisationId"
            [businessId]="businessId"
            [venueId]="venueId"
            (saveRequested)="bookingDetailsSelected($event[0], $event[1])"
        >
        </app-modal-booking-form>
    </div>
</ng-template>

<ng-template #bookingDetails>
    <div class="modal-header hstack gap-2">
        <button
            type="button"
            class="btn p-0 m-0 w-auto"
            (click)="goBackFromBookingDetails()"
        >
            <img
                ngSrc="assets/left/normal.svg"
                class="m-0"
                width="24"
                height="24"
                 alt="Back"
            >
        </button>
        <h5 class="modal-title fw-semibold ms-auto me-auto">Your Details</h5>
        <button
            type="button"
            class="btn-close ms-0"
            (click)="dismiss()"
        >
        </button>
    </div>
    <div class="modal-body p-3 pb-4 flex-fill">
        <ng-container
            *ngIf="requestBookingError"
        >
            <div
                class="alert alert-danger"
                role="alert"
                data-test="booking-details-request-error"
            >
                <div class="hstack gap-2 justify-content-between">
                    <div>{{ requestBookingError }}</div>
                    <button
                        type="button"
                        class="btn-close m-0"
                        (click)="clearRequestBookingError()"
                    >
                    </button>
                </div>
            </div>
        </ng-container>
        <app-modal-booking-details
            class="h-100 vstack"
            *ngIf="venue"
            [formGroup]="form"
            [venue]="venue"
            (nextSelected)="goNextFromBookingDetails()"
            (bookSelected)="bookSelected()"
        >
        </app-modal-booking-details>
    </div>
</ng-template>

<ng-template
    #paymentDetails
>
    <div class="modal-header hstack gap-2">
        <button
            type="button"
            class="btn p-0 m-0 w-auto"
            (click)="goBackFromPaymentDetails()"
        >
            <img
                ngSrc="assets/left/normal.svg"
                class="m-0"
                width="24"
                height="24"
                alt="Back"
            >
        </button>
        <h5 class="modal-title fw-semibold ms-auto me-auto">Payment Details</h5>
        <button
            type="button"
            class="btn-close ms-0"
            (click)="dismiss()"
        >
        </button>
    </div>
    <div class="modal-body p-3 pb-4 flex-fill">
        <ng-container
            *ngIf="requestBookingError"
        >
            <div class="alert alert-danger" role="alert">
                <div class="hstack gap-2 justify-content-between">
                    <div>{{ requestBookingError }}</div>
                    <button
                        type="button"
                        class="btn-close m-0"
                        (click)="clearRequestBookingError()"
                    >
                    </button>
                </div>
            </div>
        </ng-container>
        <app-payment-details-form
            *ngIf="venue"
            [formGroup]="form"
            [venue]="venue"
            (backSelected)="goBackFromPaymentDetails()"
            (bookSelected)="bookSelected()"
        >
        </app-payment-details-form>
    </div>
</ng-template>

<ng-template #deposit>
    <div class="modal-header hstack gap-2">
        <button
            type="button"
            class="btn p-0 m-0 w-auto"
            (click)="goBackFromDeposit()"
        >
            <img
                ngSrc="assets/left/normal.svg"
                class="m-0"
                width="24"
                height="24"
                alt="Back"
            >
        </button>
        <h5 class="modal-title fw-semibold ms-auto me-auto">Deposit</h5>
        <button
            type="button"
            class="btn-close ms-0"
            (click)="dismiss()"
        >
        </button>
    </div>
    <div class="modal-body p-3 pb-4 flex-fill">
        <ng-container
            *ngIf="requestBookingError"
        >
            <div class="alert alert-danger" role="alert">
                <div class="hstack gap-2 justify-content-between">
                    <div>{{ requestBookingError }}</div>
                    <button
                        type="button"
                        class="btn-close m-0"
                        (click)="clearRequestBookingError()"
                    >
                    </button>
                </div>
            </div>
        </ng-container>
        <app-deposit-form
            *ngIf="venue"
            [formGroup]="form"
            [venue]="venue"
            [showTitle]="false"
            (backSelected)="goBackFromDeposit()"
            (bookSelected)="bookSelected()"
        >
        </app-deposit-form>
    </div>
</ng-template>

<ng-template
    #confirmation
>
    <div class="modal-header hstack gap-2">
        <h5 class="modal-title fw-semibold ms-auto me-auto">
            Your Booking
        </h5>
        <button
            type="button"
            class="btn-close ms-0"
            (click)="dismiss()"
        >
        </button>
    </div>
    <div class="modal-body p-3 pb-4 flex-fill">
        <app-confirmation
            *ngIf="confirmationBookingDetails"
            [details]="confirmationBookingDetails"
            (bookAgainSelected)="bookAgainSelected()"
        >
        </app-confirmation>
    </div>
</ng-template>
