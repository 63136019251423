import { AbstractControl, FormArray, FormGroup } from '@angular/forms'
import { Answer } from './Answer'
import { BookingRequest } from './BookingRequest'
import { BookingSlot } from './BookingSlot'
import { FormStep } from '../booking-form/booking-form.component'
import { NGBDateTimeAdapter } from '../utilities/NgbDateTimeAdapter'
import { NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap'
import { Question, QuestionType } from './Question'

export class BookingRequestFactory {

    private ngbDateAdapter = new NgbDateNativeAdapter()
    private ngbTimeAdapter = new NGBDateTimeAdapter()

    constructor(private form: FormGroup) { }

    public make() {
        const bookingSlotForm = this.form.get(FormStep[FormStep.BookingSlot]) as FormGroup
        const bookingDetailsForm = this.form.get(FormStep[FormStep.BookingDetails]) as FormGroup
        const paymentDetailsForm = this.form.get(FormStep[FormStep.PaymentDetails]) as FormGroup
        const depositForm = this.form.get(FormStep[FormStep.Deposit]) as FormGroup
        const questions = bookingDetailsForm.get('questions') as FormArray
        const answers = questions.controls
            .map(questionControl => this.makeAnswerFromQuestionControlOrNull(questionControl))
            .filter(answer => answer !== null) as Answer[]
        let bookingSlot = bookingSlotForm.get('bookingSlot')?.value
        if (bookingSlot === undefined) {
            const ngbDate = bookingSlotForm.get('date')?.value
            const ngbTime = bookingSlotForm.get('time')?.value
            const date = this.ngbDateAdapter.toModel(ngbDate)!
            const time = this.ngbTimeAdapter.toModel(ngbTime)!
            const dateTime = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate(),
                time.getHours(),
                time.getMinutes()
            )
            bookingSlot = new BookingSlot(dateTime)
        }
        return new BookingRequest(
            bookingSlot,
            bookingDetailsForm.get('firstName')?.value,
            bookingDetailsForm.get('lastName')?.value,
            bookingDetailsForm.get('emailAddress')?.value,
            bookingDetailsForm.get('phoneNumber')?.value,
            bookingSlotForm.get('partySize')?.value,
            bookingDetailsForm.get('notes')?.value,
            bookingDetailsForm.get('agreedToMarketing')?.value,
            bookingDetailsForm.get('agreedToTerms')?.value,
            paymentDetailsForm.get('paymentMethodId')?.value,
            bookingSlotForm.get('selectedReasonId')?.value,
            bookingSlotForm.get('selectedEventId')?.value,
            answers,
            depositForm.get('paymentMethodId')?.value,
            bookingSlotForm.get('requiresWheelchairAccess')?.value
        )
    }

    private makeAnswerFromQuestionControlOrNull(questionControl: AbstractControl) {
        const question = questionControl.get('question')?.value as Question
        const answer = questionControl.get('answer')?.value ?? null
        switch (question.type) {
        case QuestionType.Checkbox:
            return new Answer(question, answer ? 'true' : 'false')
        case QuestionType.Dropdown:
            if (answer === null) {
                return null
            }
            return new Answer(question, answer)
        case QuestionType.AcknowledgeEndTime:
            return new Answer(question, answer ? 'true' : 'false')
        }
    }
}
