import { Availability } from '../domain/Availability'
import { BookingDetails } from '../domain/BookingDetails'
import { BookingSlot } from '../domain/BookingSlot'
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormGroup, FormGroupDirective } from '@angular/forms'
import { FormStep } from '../booking-form/booking-form.component'
import { NGBDateTimeAdapter } from '../utilities/NgbDateTimeAdapter'
import { NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap'
import { PaymentsService } from '../services/payments.service'
import { StripeElements } from '@stripe/stripe-js'
import { Venue } from '../domain/Venue'
import DineroFactory, { Currency, Dinero } from 'dinero.js'

@Component({
    selector: 'app-deposit-form',
    templateUrl: './deposit-form.component.html',
})
export class DepositFormComponent implements OnInit {

    @Input() showTitle = false
    @Input() showBackButton = false
    @Input() venue?: Venue
    @Input() bookingDetails?: BookingDetails
    @Output() backSelected = new EventEmitter<void>()
    @Output() paymentStarted = new EventEmitter<void>()
    @Output() bookSelected = new EventEmitter<void>()
    amount!: Dinero
    refundCutOffDays: number | null = null
    formError: string | null = null
    isSaving = false
    daysPluralMapping = {
        '=0': '# days',
        '=1': '# day',
        other: '# days',
    }
    cardDetailsAreComplete = false
    private paymentElements: StripeElements | null = null
    private ngbDateAdapter = new NgbDateNativeAdapter()
    private ngbTimeAdapter = new NGBDateTimeAdapter()

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private rootFormGroup: FormGroupDirective,
        private paymentsService: PaymentsService
    ) { }

    ngOnInit() {
        this.setUpDepositDetails()
    }

    onBackSelected() {
        this.backSelected.emit()
    }

    async onBookSelected() {
        this.isSaving = true
        this.paymentStarted.emit()
        await this.savePaymentDetails()
            .catch(error => {
                const defaultErrorMessage = 'There was an error taking your payment. ' +
                    'Please refresh the page and try again.'
                this.formError = error.message ?? defaultErrorMessage
                this.isSaving = false
                throw error
            })
            .then(() => {
                this.bookSelected.emit()
            })
            .finally(() => {
                this.isSaving = false
            })
    }

    private setUpDepositDetails() {
        if (this.venue !== undefined && this.bookingDetails !== undefined) {
            this.setUpDepositDetailsFromBookingDetails(this.venue, this.bookingDetails)
        } else {
            this.setUpDepositDetailsFromForm()
        }
    }

    private setUpDepositDetailsFromBookingDetails(venue: Venue, bookingDetails: BookingDetails) {
        const depositAmount = bookingDetails.depositAmount
        if (depositAmount === null) {
            return
        }
        const depositCurrencyCode = bookingDetails.depositCurrencyCode
        if (depositCurrencyCode === null) {
            return
        }
        this.amount = DineroFactory({
            amount: depositAmount,
            currency: depositCurrencyCode as Currency,
        })
        this.refundCutOffDays = venue.depositRefundCutOffDays
        this.mountSetUpPaymentDetailsElement(venue, bookingDetails.dateTime)
            .catch(_ => {
                this.formError =
                    'There was an error setting up your booking. ' +
                    'Please refresh the page and try again.'
            })
    }

    private setUpDepositDetailsFromForm() {
        const bookingSlotForm = this.rootFormGroup.control.get('BookingSlot')
        let bookingSlot: BookingSlot = bookingSlotForm?.get('bookingSlot')?.value
        if (bookingSlot === undefined) {
            const ngbDate = bookingSlotForm?.get('date')?.value
            const ngbTime = bookingSlotForm?.get('time')?.value
            const date = this.ngbDateAdapter.toModel(ngbDate)!
            const time = this.ngbTimeAdapter.toModel(ngbTime)!
            const dateTime = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate(),
                time.getHours(),
                time.getMinutes()
            )
            bookingSlot = new BookingSlot(dateTime)
        }
        if (bookingSlot === null) {
            return
        }
        const availabilityControl = bookingSlotForm?.get('availability')
        const availability = availabilityControl?.value as Availability | null
        if (availability === null) {
            return
        }
        const venue: Venue = this.venue
            ?? availability?.venue
        if (venue === null) {
            return
        }
        const areaId: string = bookingSlotForm?.get('selectedAreaId')?.value as string
        if (areaId === null) {
            return
        }
        const depositAmount = availability
            .reservableOptionWithConfiguration(
                areaId,
                bookingSlotForm?.get('partySize')?.value,
                bookingSlotForm?.get('selectedReasonId')?.value,
                bookingSlotForm?.get('selectedEventId')?.value,
                bookingSlot.dateTime
            )?.depositChargeAmount
        if (depositAmount === null) {
            return
        }
        this.amount = DineroFactory({
            amount: depositAmount!,
            currency: venue.currencyCode as Currency,
        })
        this.refundCutOffDays = venue.depositRefundCutOffDaysForConfiguration(
            bookingSlotForm?.get('selectedEventId')?.value
        )
        this.mountSetUpPaymentDetailsElement(venue, bookingSlot.dateTime)
            .catch(_ => {
                this.formError =
                    'There was an error setting up your booking. ' +
                    'Please refresh the page and try again.'
            })
    }

    private async mountSetUpPaymentDetailsElement(
        venue: Venue,
        bookingStart: Date
    ) {
        const paymentsAccountId = venue.paymentsAccountId
        if (paymentsAccountId === null) {
            throw new Error('Payments account ID not set up')
        }
        this.paymentsService.setUpForAccount(paymentsAccountId)
        const payment = await this.paymentsService.makePaymentIntentClientSecret(
            venue.organisationId,
            venue.businessId,
            bookingStart
        )
        this.paymentElements = await this.paymentsService.createStripeElements(payment.clientSecret)
        const paymentIntent = this.paymentElements.create('payment')
        paymentIntent.mount('#payment-intent')
        paymentIntent.on('change', event => {
            this.cardDetailsAreComplete = event.complete
            this.changeDetectorRef.detectChanges()
        })
    }

    private async savePaymentDetails() {
        const elements = this.paymentElements
        if (elements === null) {
            throw new Error('Please refresh the page and try again')
        }
        const paymentMethodId = await this.paymentsService.savePaymentDetails(elements)
        const formName = FormStep[FormStep.Deposit]
        const form = this.rootFormGroup.control.get(formName) as FormGroup
        form.get('paymentMethodId')?.setValue(paymentMethodId)
    }
}
