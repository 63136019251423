import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { FormArray, FormGroup, FormGroupDirective } from '@angular/forms'
import { FormStep } from '../booking-form/booking-form.component'
import { QuestionType } from '../domain/Question'
import { Subject } from 'rxjs'
import { Venue } from '../domain/Venue'
import { environment } from '../../environments/environment'
import { markAllFormControlsAsTouched } from '../utilities/form-touched'

@Component({
    selector: 'app-modal-booking-details',
    templateUrl: './modal-booking-details.component.html',
})
export class ModalBookingDetailsComponent implements OnInit, OnDestroy {
    protected readonly QuestionType = QuestionType

    @Input() venue!: Venue
    @Output() nextSelected = new EventEmitter<void>()
    @Output() bookSelected = new EventEmitter<void>()
    privacyPolicyURL = environment.privacyPolicyURL
    slotForm!: FormGroup
    detailsForm!: FormGroup
    private onDestroy$ = new Subject<void>()

    constructor(
        private rootFormGroup: FormGroupDirective
    ) { }

    ngOnInit() {
        this.slotForm = this.rootFormGroup.form.get(FormStep[FormStep.BookingSlot]) as FormGroup
        this.detailsForm = this.rootFormGroup.form.get(FormStep[FormStep.BookingDetails]) as FormGroup
    }

    ngOnDestroy() {
        this.onDestroy$.next()
        this.onDestroy$.complete()
    }

    get questions() {
        return this.detailsForm.get('questions') as FormArray
    }

    next() {
        markAllFormControlsAsTouched(this.detailsForm)
        if (this.detailsForm.invalid) {
            return
        }
        this.nextSelected.emit()
    }

    book() {
        markAllFormControlsAsTouched(this.detailsForm)
        if (this.detailsForm.invalid) {
            return
        }
        this.bookSelected.emit()
    }
}
