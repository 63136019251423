<form
    class="vstack gap-3"
    appVenueColour
    [primaryColour]="venue.primaryColour"
    [formGroup]="detailsForm"
>
    <div class="vstack gap-2 flex-grow-0">
        <div class="hstack gap-3">
            <input
                id="inputFirstName"
                class="form-control"
                formControlName="firstName"
                type="text"
                placeholder="First Name"
                data-test="first-name"
                [ngClass]="{
                    'is-invalid': detailsForm.get('firstName')!!.invalid && detailsForm.get('firstName')!!.touched
                }"
            />
            <input
                id="inputLastName"
                class="form-control"
                formControlName="lastName"
                type="text"
                placeholder="Surname"
                data-test="last-name"
            />
        </div>
        <input
            id="inputEmailAddress"
            class="form-control"
            formControlName="emailAddress"
            type="text"
            placeholder="Email Address"
            data-test="email-address"
            [ngClass]="{
                'is-invalid': detailsForm.get('emailAddress')!!.invalid && detailsForm.get('emailAddress')!!.touched
            }"
        />
        <input
            id="inputPhoneNumber"
            class="form-control"
            formControlName="phoneNumber"
            type="text"
            placeholder="Phone Number"
            data-test="phone-number"
            [ngClass]="{
                'is-invalid': detailsForm.get('phoneNumber')!!.invalid && detailsForm.get('phoneNumber')!!.touched
            }"
        />
        <textarea
            class="form-control"
            placeholder="Booking Notes (optional)"
            id="inputNotes"
            formControlName="notes"
            data-test="notes"
            style="height: 100px"
        >
        </textarea>
    </div>

    <div
        *ngIf="questions.controls.length > 0"
        class="vstack gap-2 flex-grow-0"
    >
        <ng-container formArrayName="questions">
            <ng-container
                *ngFor="let questionControl of questions.controls; let i = index"
                [formGroupName]="i"
            >
                <ng-container
                    *ngIf="questionControl.get('question')!!.value as question"
                >
                    <div
                        *ngIf="question.type === QuestionType.Checkbox"
                        class="form-check hstack gap-2 align-items-center"
                        data-test="checkbox-question"
                    >
                        <input
                            class="form-check-input"
                            type="checkbox"
                            [id]="'question-' + i"
                            formControlName="answer"
                            [ngClass]="{
                                'is-invalid': questionControl.get('answer')!!.invalid && questionControl.get('answer')!!.touched
                            }"
                        >
                        <label
                            class="form-check-label"
                            [for]="'question-' + i"
                        >
                            {{ question.text }}
                            <span
                                *ngIf="questionControl.get('answer')!!.invalid && questionControl.get('answer')!!.touched"
                                class="text-danger"
                            >
                                (Required)
                            </span>
                        </label>
                    </div>
                    <div
                        *ngIf="question.type === QuestionType.Dropdown"
                        data-test="dropdown-question"
                    >
                        <label
                            for="question-{{ i }}"
                            class="form-label"
                        >
                            {{ question.text }}
                        </label>
                        <div
                            ngbDropdown
                        >
                            <button
                                class="btn btn-outline-secondary"
                                id="question-{{ i }}"
                                ngbDropdownToggle
                                [ngClass]="{
                                    'is-invalid': questionControl.get('answer')!!.invalid && questionControl.get('answer')!!.touched
                                }"
                            >
                                <span
                                    *ngIf="!questionControl.get('answer')!!.value && !question.required"
                                >
                                    No Selection
                                </span>
                                <span
                                    *ngIf="!questionControl.get('answer')!!.value && question.required"
                                >
                                    Required
                                </span>
                                <span *ngIf="questionControl.get('answer')!!.value"
                                >
                                    {{ questionControl.get('answer')!!.value }}
                                </span>
                            </button>
                            <div ngbDropdownMenu>
                                <button
                                    *ngIf="!question.required"
                                    class="dropdown-item hstack gap-2 align-items-baseline justify-content-between"
                                    (click)="questionControl.get('answer')!!.setValue(null)"
                                >
                                    No Selection
                                    <i
                                        *ngIf="!questionControl.get('answer')!!.value"
                                        class="bi bi-check-circle"
                                    >
                                    </i>
                                </button>
                                <button
                                    *ngFor="let option of question.options"
                                    class="dropdown-item hstack gap-2 align-items-baseline justify-content-between"
                                    (click)="questionControl.get('answer')!!.setValue(option)"
                                >
                                    {{ option }}
                                    <i
                                        *ngIf="questionControl.get('answer')!!.value === option"
                                        class="bi bi-check-circle"
                                    >
                                    </i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div
                        *ngIf="question.type === QuestionType.AcknowledgeEndTime"
                        class="form-check hstack gap-2 align-items-center"
                        data-test="acknowledge-end-time"
                    >
                        <input
                            class="form-check-input"
                            type="checkbox"
                            [id]="'question-' + i"
                            formControlName="answer"
                            [ngClass]="{
                                'is-invalid': questionControl.get('answer')!!.invalid && questionControl.get('answer')!!.touched
                            }"
                        >
                        <label
                            class="form-check-label"
                            [for]="'question-' + i"
                        >
                            I acknowledge that my booking ends at
                            <span class="fw-bold">
                                {{ slotForm.get('endTime')!!.value | ngbTime | date: 'shortTime' }}
                            </span>
                            <span
                                *ngIf="questionControl.get('answer')!!.invalid && questionControl.get('answer')!!.touched"
                                class="text-danger"
                            >
                                (Required)
                            </span>
                        </label>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>

    <div class="vstack gap-1 flex-grow-0">
        <div class="form-check hstack gap-2 align-items-center">
            <input
                class="form-check-input"
                type="checkbox"
                id="agreedToMarketing"
                formControlName="agreedToMarketing"
            >
            <label class="form-check-label" for="agreedToMarketing">
                I would like to receive news and offers from {{ venue.displayName }}
            </label>
        </div>
        <div class="form-check hstack gap-2 align-items-center">
            <input
                class="form-check-input"
                type="checkbox"
                id="agreedToTerms"
                formControlName="agreedToTerms"
                data-test="agreed-to-terms"
                [ngClass]="{
                    'is-invalid': detailsForm.get('agreedToTerms')!!.invalid && detailsForm.get('agreedToTerms')!!.touched
                }"
            >
            <label for="agreedToTerms">
                I have read and accept the TableSense
                <a class="text-body" [href]="privacyPolicyURL" target="_blank">Privacy Policy</a>
                agreement
                <span
                    *ngIf="detailsForm.get('agreedToTerms')!!.invalid && detailsForm.get('agreedToTerms')!!.touched"
                    class="text-danger small"
                >
                    You need to accept the Privacy Policy to continue with the booking
                </span>
            </label>
        </div>
    </div>

    <div class="vstack justify-content-end">
        <button
            *ngIf="detailsForm.get('paymentDetailsRequired')!!.value || detailsForm.get('depositRequired')!!.value"
            type="button"
            class="btn btn-primary"
            data-test="confirm-details-next"
            (click)="next()"
        >
            Confirm & Continue
        </button>
        <button
            *ngIf="!detailsForm.get('paymentDetailsRequired')!!.value && !detailsForm.get('depositRequired')!!.value"
            type="button"
            class="btn btn-primary"
            data-test="confirm-details-book"
            (click)="book()"
        >
            Confirm & Book
        </button>
    </div>
</form>
