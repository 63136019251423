export enum QuestionType {
    Checkbox,
    Dropdown,
    AcknowledgeEndTime,
}

export class Question {
    constructor(
        public id: string,
        public text: string,
        public required: boolean,
        public type: QuestionType,
        public options: string[],
        public reasonIds: string[]
    ) { }

    get isTickable(): boolean {
        return this.type === QuestionType.Checkbox || this.type === QuestionType.AcknowledgeEndTime
    }
}
