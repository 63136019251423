
export class Event {

    constructor(
        public id: string,
        public displayName: string,
        public displayOrder: number,
        public description: string,
        public imageId: string | null,
        public cancellationChargeAmount: number | null,
        public cancellationChargeCutOffHours: number | null,
        public depositAmount: number | null,
        public depositRefundCutOffDays: number | null,
        public questionIds: string[]
    ) { }
}
